import { styled } from '@mui/material'
import { ReactComponent as Whatsapp } from '../../assets/whatsapp.svg'

export const MUIStyledLink = styled('a')(({ theme }) => ({
  width: '100%',
  maxWidth: '425px',
  display: 'block',
}))

export const MUIStyledButton = styled('button')(({ theme }) => ({
  width: '100%',
  padding: '1.1rem 1rem',
  borderRadius: '10px',
}))

export const IconWhatsapp = styled(Whatsapp)(({ theme }) => ({
  width: '28px',
  marginRight: '1rem',
}))

export const MUIStyledButtonLabel = styled('span')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: theme.customTypography.button.fontSize,
  fontWeight: theme.customTypography.button.fontWeight,
}))
