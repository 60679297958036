export const customPalette = {
  primary: { main: '#102C6D', hover: '#09235A' },
  secondary: { main: '#F70000', hover: '#B51114' },
  text: { primary: '#333333', secondary: '#666666' },
  background: {
    default: '#EBEBEB',
    main: '#EBEBEB',
    primary50: '#102C6D50',
    primary90: '#102C6D95',
    secondary50: '#F7000050',
    secondary90: '#F7000080',
  },
}
