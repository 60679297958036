import { styled } from '@mui/material'

export const MUIGlobalContainer = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: '1232px',
  margin: '0 auto',
  padding: '0 1rem',
}))

export const MUIMainHome = styled('main')(({ theme }) => ({}))
