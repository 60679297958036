import { Card, styled } from '@mui/material'

export const MUIStyledHeroSection = styled('section')(({ theme }) => ({
  width: '100%',
  height: 'fit-content',
  position: 'relative',
  // ,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  padding: '8rem 0',
  [theme.breakpoints.down('sm')]: {
    padding: '3rem 0 5rem',
  },
}))

export const MUIStyledAbsolutBg = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  zIndex: '-2',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: theme.customPalette.background.primary90,
  },
}))
export const MUIStyledImageBg = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  objectPosition: '50% 20%',
}))

export const MUIStyledHeroContent = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4rem',
}))

export const MUIStyledHeroCard = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: '1100px',
  padding: '2rem 1rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: '16px',
}))

export const MUIStyledHeroTitle = styled('h1')(({ theme }) => ({
  fontSize: theme.customTypography.h1.fontSize,
  fontWeight: theme.customTypography.h1.fontWeight,
  lineHeight: theme.customTypography.h1.lineHeight,
  color: theme.customPalette.primary.main,
  marginBottom: '1rem',
}))

export const MUIStyledHeroDescription = styled('p')(({ theme }) => ({
  fontSize: theme.customTypography.body1.fontSize,
  fontWeight: theme.customTypography.body1.fontWeight,
  lineHeight: '35px',
  color: theme.customPalette.text.primary,
  maxWidth: '950px',
}))
