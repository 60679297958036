import { IWhereIsSection } from './types'

export const whereIsSectionMockProps: IWhereIsSection = {
  title: 'Visite nossas unidades ou peça pelo Whatsapp',
  whereIsCards: [
    {
      imageUrl: 'gmb-assets/adress02_tnc22d',
      adress: `Av. São Sebastião, 31-B, Santa Clara - Divinópolis`,
      phone: '(37) 3222-3056',
    },
    {
      imageUrl: 'gmb-assets/new-assets-2024/UltraGás-1_jwhym2',
      adress: `Av. Autorama, 151, Catalão - Divinópolis`,
      phone: '(37) 3222-3056',
    },
  ],
}
