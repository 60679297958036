import { styled } from '@mui/material'

export const MUIStyledSection = styled('section')(({ theme }) => ({
  width: '100%',
  height: '300px',
  position: 'relative',
}))

export const MUIStyledAbsolutBg = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  zIndex: '-2',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: theme.customPalette.background.primary90,
  },
}))

export const MUIStyledImageBg = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
}))

export const MUIStyledContent = styled('div')(({ theme }) => ({
  height: '300px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))
