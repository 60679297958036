import { MUIGlobalContainer } from '../../styles/global'
import { buildImage } from '../../utils/cloudinary'
import { showProductsSectionMockProps } from './mocks'
import {
  MUIStyledShowProducts,
  MUIStyledShowProductsContent,
  MUIStyledShowProductsTitle,
  MUIStyledShowProductsDescription,
  MUIStyledShowProductsGridContent,
  MUIStyledTopicsContainer,
  MUIStyledTopicContent,
  MUIStyledButtonBlock,
  MUITopicText,
  MUIStyledImageBlock,
  MUIStyledImage,
} from './styles'

import MUIButton from '../MUIButton'

const { title, description, imageUrl, topics } = showProductsSectionMockProps

const MUIShowProductsSection = () => {
  return (
    <MUIStyledShowProducts>
      <MUIGlobalContainer>
        <MUIStyledShowProductsContent>
          <MUIStyledShowProductsTitle>{title}</MUIStyledShowProductsTitle>
          <MUIStyledShowProductsDescription>
            {description}
          </MUIStyledShowProductsDescription>
          <MUIStyledShowProductsGridContent>
            <MUIStyledTopicsContainer>
              {topics.map((topic, index) => (
                <MUIStyledTopicContent key={index}>
                  {topic.svg}
                  <MUITopicText>{topic.text}</MUITopicText>
                </MUIStyledTopicContent>
              ))}
              <MUIStyledButtonBlock
                sx={{ display: { md: 'block', xs: 'none' } }}
              >
                <MUIButton />
              </MUIStyledButtonBlock>
            </MUIStyledTopicsContainer>
            <MUIStyledImageBlock>
              <MUIStyledImage
                src={buildImage(imageUrl)
                  // @ts-ignore
                  .resize('w_504,h_565')
                  .toURL()}
                alt={'Botijões'}
              />
            </MUIStyledImageBlock>
            <MUIStyledButtonBlock sx={{ display: { md: 'none', xs: 'flex' } }}>
              <MUIButton />
            </MUIStyledButtonBlock>
          </MUIStyledShowProductsGridContent>
        </MUIStyledShowProductsContent>
      </MUIGlobalContainer>
    </MUIStyledShowProducts>
  )
}

export default MUIShowProductsSection
