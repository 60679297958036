import { MUIGlobalContainer } from '../../styles/global'
import { buildImage } from '../../utils/cloudinary'
import { headerMockProps } from './mocks'
import {
  MUIStyledHeader,
  MUIStyledHeaderContent,
  MUIStyledLogo,
} from './styles'

const { logo } = headerMockProps

const MUIHeader = () => {
  return (
    <MUIStyledHeader>
      <MUIGlobalContainer>
        <MUIStyledHeaderContent>
          <MUIStyledLogo
            src={buildImage(logo.url)
              // @ts-ignore
              .resize('w_400,h_105')
              .toURL()}
            alt={logo.alt}
          />
        </MUIStyledHeaderContent>
      </MUIGlobalContainer>
    </MUIStyledHeader>
  )
}

export default MUIHeader
