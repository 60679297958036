import { createTheme } from '@mui/material'
import { customPalette } from './customPalette'
import { customTypography } from './customTypography'

import './types'

export const theme = createTheme({
  customPalette,
  customTypography,
})
