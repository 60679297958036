import { MUIGlobalContainer } from '../../styles/global'
import { buildImage } from '../../utils/cloudinary'
import { footerMockProps } from './mocks'
import {
  MUIStyledFooter,
  MUIStyledCopyright,
  MUIStyledFooterContent,
  MUIStyledLogo,
} from './styles'

const { copyright, logo } = footerMockProps

const MUIFooter = () => {
  return (
    <MUIStyledFooter>
      <MUIGlobalContainer>
        <MUIStyledFooterContent>
          <MUIStyledCopyright>{copyright}</MUIStyledCopyright>
          <MUIStyledLogo
            src={buildImage(logo.url)
              // @ts-ignore
              .resize('w_400,h_105')
              .toURL()}
            alt={logo.alt}
          />
        </MUIStyledFooterContent>
      </MUIGlobalContainer>
    </MUIStyledFooter>
  )
}

export default MUIFooter
