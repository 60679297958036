import { MUIGlobalContainer } from '../../styles/global'
import { buildImage } from '../../utils/cloudinary'
import { whereIsSectionMockProps } from './mocks'
import {
  MUIStyledWhereIsSection,
  MUICardAdress,
  MUICardContent,
  MUICardImageBlock,
  MUICardImage,
  MUICardPhone,
  MUIStyledTitle,
  MUIStyledWhereIrContent,
  MUIStyledWhereIsCard,
} from './styles'

const { title, whereIsCards } = whereIsSectionMockProps

const MUIWhereIsSection = () => {
  return (
    <MUIStyledWhereIsSection>
      <MUIGlobalContainer>
        <MUIStyledTitle>{title}</MUIStyledTitle>
        <MUIStyledWhereIrContent>
          {whereIsCards.map((card, index) => (
            <MUIStyledWhereIsCard key={index}>
              <MUICardImageBlock>
                <MUICardImage
                  src={buildImage(card.imageUrl)
                    // @ts-ignore
                    .resize('w_1000,h_900')
                    .toURL()}
                  alt={'company'}
                />
              </MUICardImageBlock>
              <MUICardContent>
                <MUICardAdress>{card.adress}</MUICardAdress>
                <MUICardPhone>{card.phone}</MUICardPhone>
              </MUICardContent>
            </MUIStyledWhereIsCard>
          ))}
        </MUIStyledWhereIrContent>
      </MUIGlobalContainer>
    </MUIStyledWhereIsSection>
  )
}

export default MUIWhereIsSection
