import { styled } from '@mui/material'

export const MUIStyledWhereIsSection = styled('div')(({ theme }) => ({
  padding: '4rem 0',
}))
export const MUIStyledTitle = styled('h2')(({ theme }) => ({
  fontSize: theme.customTypography.h2.fontSize,
  fontWeight: theme.customTypography.h2.fontWeight,
  lineHeight: theme.customTypography.h2.lineHeight,
  color: theme.customPalette.primary.main,
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    fontSize: theme.customTypography.h3.fontSize,
    fontWeight: theme.customTypography.h3.fontWeight,
    lineHeight: theme.customTypography.h3.lineHeight,
  },
}))
export const MUIStyledWhereIrContent = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '3rem',
  paddingTop: '2.5rem',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.down('sm')]: {
    gap: '4.5rem',
  },
}))

export const MUIStyledWhereIsCard = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '2rem',
  [theme.breakpoints.down('md')]: {
    gap: '3rem',
  },
  [theme.breakpoints.down('sm')]: {
    gap: '1.5rem',
    flexDirection: 'column',
    textAlign: 'center',
  },
}))
export const MUICardImageBlock = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: '200px',
  borderTopLeftRadius: '16px',
  borderBottomLeftRadius: '16px',
  aspectRatio: '40/35',
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '350px',
    borderRadius: '16px',
    aspectRatio: '38/26',
  },
}))
export const MUICardImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
}))
export const MUICardContent = styled('div')(({ theme }) => ({}))
export const MUICardAdress = styled('div')(({ theme }) => ({
  maxWidth: '250px',
  fontSize: theme.customTypography.body1.fontSize,
  fontWeight: theme.customTypography.body1.fontWeight,
  lineHeight: '30px',
  color: theme.customPalette.text.primary,
  [theme.breakpoints.down('md')]: {
    fontSize: theme.customTypography.body2.fontSize,
    fontWeight: theme.customTypography.body2.fontWeight,
    lineHeight: theme.customTypography.body2.lineHeight,
  },
}))
export const MUICardPhone = styled('div')(({ theme }) => ({
  fontSize: theme.customTypography.body1.fontSize,
  fontWeight: theme.customTypography.body1.fontWeight,
  lineHeight: theme.customTypography.body1.lineHeight,
  color: theme.customPalette.text.primary,
  paddingTop: '2rem',
  [theme.breakpoints.down('md')]: {
    fontSize: theme.customTypography.body2.fontSize,
    fontWeight: theme.customTypography.body2.fontWeight,
    lineHeight: theme.customTypography.body2.lineHeight,
  },
  [theme.breakpoints.down('sm')]: {
    paddingTop: '1rem',
  },
}))
