import { styled } from '@mui/material'

export const MUIStyledShowProducts = styled('section')(({ theme }) => ({
  width: '100%',
  padding: '5rem 0',
  backgroundColor: theme.customPalette.primary.main,
}))

export const MUIStyledShowProductsContent = styled('div')(({ theme }) => ({
  width: '100%',
}))

export const MUIStyledShowProductsTitle = styled('h2')(({ theme }) => ({
  fontSize: theme.customTypography.h2.fontSize,
  fontWeight: theme.customTypography.h2.fontWeight,
  lineHeight: theme.customTypography.h2.lineHeight,
  color: theme.customPalette.background.default,
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    fontSize: theme.customTypography.h3.fontSize,
    fontWeight: theme.customTypography.h3.fontWeight,
    lineHeight: theme.customTypography.h3.lineHeight,
    textAlign: 'start',
  },
}))

export const MUIStyledShowProductsDescription = styled('p')(({ theme }) => ({
  fontSize: theme.customTypography.body1.fontSize,
  fontWeight: theme.customTypography.body1.fontWeight,
  lineHeight: theme.customTypography.body1.lineHeight,
  color: theme.customPalette.background.default,
  padding: '2rem 0 4rem',
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    fontSize: theme.customTypography.body2.fontSize,
    fontWeight: theme.customTypography.body2.fontWeight,
    lineHeight: theme.customTypography.body2.lineHeight,
    textAlign: 'start',
    padding: '2rem 0 1.5rem',
  },
}))

export const MUIStyledShowProductsGridContent = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: '3rem',
  },
}))

export const MUIStyledTopicsContainer = styled('div')(({ theme }) => ({
  width: 'fit-content',
}))

export const MUIStyledTopicContent = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '2.5rem',
  padding: '1.5rem 0',
}))

export const MUIStyledButtonBlock = styled('div')(({ theme }) => ({
  paddingTop: '2rem',
  width: '100%',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    paddingTop: '3rem',
  },
}))

export const MUITopicText = styled('h3')(({ theme }) => ({
  fontSize: theme.customTypography.h3.fontSize,
  fontWeight: theme.customTypography.h3.fontWeight,
  lineHeight: theme.customTypography.h3.lineHeight,
  color: theme.customPalette.background.default,
  [theme.breakpoints.down('md')]: {
    fontSize: theme.customTypography.h4.fontSize,
    fontWeight: theme.customTypography.h4.fontWeight,
    lineHeight: theme.customTypography.h4.lineHeight,
  },
}))

export const MUIStyledImageBlock = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: '504px',
  aspectRatio: '504/565',
  [theme.breakpoints.down('md')]: {},
}))

export const MUIStyledImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
}))
